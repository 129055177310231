import React from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import './App.css';
import { Button } from './components/Button';

function App() {
  return (
    <>
    <Router>
    <Navbar/>
    <Switch>
      <Route path='/' exact/>
    </Switch>

    <Button/>
    </Router>
    
      
    </>
  );
}

export default App;
